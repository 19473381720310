import { Link } from "gatsby";
//import PropTypes from "prop-types";
import React from "react";
import { css } from "@emotion/core";

import logo_dot from "../images/icon_ra.png";
//import logo_dot from "../images/icon_tree_ra.png";

//const Navbar = props =>
class Navbar extends React.Component {
  constructor(props) {
    super(props);

    this.handleScroll.bind(this);

    this.state = { transparent: true };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    //this.handleScroll();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    if (this.state.transparent && window.pageYOffset > 0) {
      this.setState({ transparent: false });
    } else if (!this.state.transparent && window.pageYOffset <= 0) {
      this.setState({ transparent: true });
    }
  };

  render() {
    const { showHome } = this.props;
    return (
      <nav
        className={`navbar fixed-top navbar-expand-sm ${this.state
          .transparent && "navbar-transparent"}`}
        css={css`
          padding: 0;
        `}
      >
        <div className="container">
          <div>
            {!this.state.transparent && (
              <a href="/" className="nav-link">
                <img
                  src={logo_dot}
                  alt="Red Apple - dot logo"
                  css={css`
                    width: 32px;
                  `}
                />
              </a>
            )}
          </div>
          {/*<div className="collapse navbar-collapse">*/}

          <div className="navbar-toggler">
            <ul className="navbar-nav ml-auto">
              {showHome ? (
                <li>
                  <a href="/" className="nav-link">
                    <i />
                    Home
                  </a>
                </li>
              ) : (
                <li>
                  <Link to={`contact-form`} className="nav-link">
                    <i />
                    Contact Us
                  </Link>
                </li>
              )}
            </ul>
          </div>
          <div className="collapse navbar-collapse">
            {
              <ul className="navbar-nav ml-auto">
                {showHome && (
                  <li>
                    <a href="/" className="nav-link">
                      <i />
                      Home
                    </a>
                  </li>
                )}
                <li className="dropdown nav-item">
                  <a href="/#about" className="nav-link">
                    <i />
                    About
                  </a>
                </li>
                <li>
                  <a href="/#solutions" className="nav-link">
                    <i />
                    Solutions
                  </a>
                </li>
                <li>
                  <Link to={`contact-form`} className="nav-link">
                    <i />
                    Contact Us
                  </Link>
                </li>
                {/*}
              <li>
                <Link to={`/`} className="nav-link btn btn-outline-primary">
                  <i />
                  Sign In
                </Link>
              </li>
              */}
              </ul>
            }
          </div>
        </div>
      </nav>
    );
  }
}

export default Navbar;
