import React from "react";
//import PropTypes from "prop-types";
//import { css } from "@emotion/core";

const Footer = ({ children }) => (
  <footer>
    <br />
    <p className="text-center">
      Copyright © {new Date().getFullYear()} Red Apple Solutions LLC.
    </p>
  </footer>
);

export default Footer;
