import React from "react";

import Layout from "../components/layout";
import HEAD from "../components/head";

const NotFoundPage = () => (
  <Layout>
    <HEAD title="404: Not found" />
    <div className="container text-center">
      <div className="mr-auto ml-auto">
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist...</p>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
