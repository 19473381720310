import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";

import Navbar from "./navbar";
import Footer from "./footer";
import "./layout.scss";

import { css } from "@emotion/core";

const Layout = ({ children, showHome }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <div>
        <Navbar showHome={showHome} />
        <main
          role="main"
          css={css`
            padding: 0px;
          `}
        >
          {children}
        </main>
        <Footer />
      </div>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
